<template>
    <v-container fluid>
        
        <v-tabs v-model="tab" fixed-tabs show-arrows>
            <v-tab :to="`/areas/${$route.params.id}/editar`" class="ordemArea"> Area </v-tab>
            <tab-rotas-box class="ordemBox" :temPermissao="
                $store.getters['usuario/temPermissao']('listar.areas.boxes')
            " :to="`/areas/${$route.params.id}/box`" />
            <v-tab class="ordemTabela"> Tabelas </v-tab>
            <tab-rotas-convenios class="ordemConvenio" :temPermissao="
                $store.getters['usuario/temPermissao']('listar.areas.convenios')
            " :to="`/areas/${$route.params.id}/convenio`">
            </tab-rotas-convenios>

            <tab-rotas-tipos-pagamentos class="ordemPagamento" :to="`/areas/${$route.params.id}/tipos-pagamentos`"
                :temPermissao="
                    $store.getters['usuario/temPermissao'](
                        'listar.areas.tipospagamentos'
                    )
                " />
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item> </v-tab-item>
            <v-tab-item> </v-tab-item>
            <v-tab-item>
                <v-card-actions>
                    <v-row class="mt-1">
                        <v-col>
                            <btn-adicionar :temPermissao="
                                $store.getters['usuario/temPermissao'](
                                    'inserir.tabelas'
                                )
                            " :to="`../${$route.params.id}/tabela/adicionar`" />
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-row class="mt-4">
                    <v-col>
                        <v-data-table disable-filtering disable-sort :server-items-length="totalRegistros"
                            :headers="cabecalho" :items="items" :options.sync="paginacao" :footer-props="{
                                'items-per-page-options': [10, 20, 50],
                            }" @update:options="carregarBoxEmpresas">
                            <template v-slot:[`item.actions`]="{ item }" class="text-right">
                                <table-btn-mostrar :temPermissao="
                                    $store.getters['usuario/temPermissao'](
                                        'mostrar.tabelas'
                                    )
                                " :href="
    `/areas/${item.area_id}/tabela/${item.id}/mostrar`
" />
                                <table-btn-editar :temPermissao="
                                    $store.getters['usuario/temPermissao'](
                                        'editar.tabelas'
                                    )
                                " :href="
    `/areas/${item.area_id}/tabela/${item.id}/editar`
" />

                                <table-btn-editar :href="
                                    `/areas/${item.area_id}/tabela/${item.id}/editar`
                                " />
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item> </v-tab-item>
            <v-tab-item> </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import areas from "@/api/areas";

export default {
    data() {
        return {
            tab: 2,
           
            totalRegistros: 0,
            cabecalho: [
                { text: "#", value: "id" },
                { text: "Descrição", value: "descricao" },
                { text: "Status", value: "status" },
                { text: "Padrão", value: "padrao" },
                { text: "Convênio", value: "tem_convenio" },
                { text: "Veículo", value: "tipo_veiculo_id" },
                { text: "Ações", value: "actions", align: "right" },
            ],
            items: [],
            paginacao: {},
        };
    },

    methods: {
        async carregarBoxEmpresas() {
            try {
                this.$store.commit(`layout/SET_LOADING`, true);

                const area_id = this.$route.params.id;

                const resposta = await areas.listarAreasTabela(area_id, {
                    pagina: this.paginacao.page,
                    por_pagina: this.paginacao.itemsPerPage,
                });

                this.items = resposta.data.data;
                this.items.forEach((item) => {
                    if (item.tem_convenio == 1) {
                        item.tem_convenio = "Possui convênio";
                    } else {
                        item.tem_convenio = "";
                    }

                    if (item.ativo == 1) {
                        item.status = "Ativo";
                    } else {
                        item.status = "Inativo";
                    }

                    if (item.padrao == 1) {
                        item.padrao = "Tabela Padrão";
                    } else {
                        item.padrao = "";
                    }

                    if (item.tipo_veiculo_id == 1) {
                        item.tipo_veiculo_id = "Carro";
                    } else if (item.tipo_veiculo_id == 2) {
                        item.tipo_veiculo_id = "Moto";
                    } else if (item.tipo_veiculo_id == 3) {
                        item.tipo_veiculo_id = "Caminhão";
                    } else {
                        item.tipo_veiculo_id = "";
                    }
                });

                this.totalRegistros = resposta.data.meta.total;
            } catch (e) {
                this.$snackbar.mostrar({
                    cor: "error",
                    mensagem: this.$erroApi._(e),
                });
            } finally {
                this.$store.commit(`layout/SET_LOADING`, false);
            }
        },
    },
};
</script>
